<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="sanciones.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             responsiveLayout="scroll"
             removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="fullname" header="Id">
      <template #body="{data}">
        {{ data.id }}
      </template>
    </Column>

    <Column field="observacion" header="Observaciones" style="min-width:10rem">
      <template #body="{data}">
        {{ data.observacion }}
      </template>
    </Column>

    <Column field="tipoName" header="Incidente ID" style="min-width:10rem">
      <template #body="{data}">
        {{ data.incidenteId }}
      </template>
    </Column>

    <Column field="path" header="Adjunto">
      <template #body="{data}">
        <template v-if="data.path">
          <Button :label="data.path.split(',')[1]"
                  class="p-button-link"
                  style="font-size: 0.75rem"
                  @click="verPdf(data.path.split(',')[0])"
          />
        </template>
        <template v-else>
          No tiene
        </template>
      </template>
    </Column>

    <Column field="createdAt" header="Fecha Notificacion" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaNotificacion, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
    </Column>

    <Column field="createdAt" header="Fecha Vencimiento" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaVencimiento, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
    </Column>

    <Column field="createdBy" header="Creado Por" style="min-width:10rem" :showFilterMatchModes="false">
      <template #body="{data}">
        {{ data.createdByName }}
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="usuarios" optionLabel="fullName" optionValue="id" placeholder="Cualquiera" :filter="true" />
      </template>
    </Column>
    <Column header="Acciones" style="width: 10rem">
      <template #body="{data}">
        <div class="flex gap-4">
          <Button icon="pi pi-eye" class="p-button-rounded p-button-help" @click="ver(data)" />
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" />
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.offset"
             :rows="paginator.limit"
             :totalRecords="sanciones.count"
             :rowsPerPageOptions="[2,10,20,30]"
             @page="onPage($event)"
  />
</div>
</template>

<script>
import desacatosStore from '../../../../../store/desacatos.store'
import { computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'

export default {
  name: 'TablaDesacatos',
  setup () {
    const router = useRouter()
    const _PdfService = new PdfService()
    const sanciones = computed(() => desacatosStore.getters.desacatos)
    const usuarios = ref([])

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const ver = ({ tipo, base }) => {
      if (tipo === 1) {
        router.push({ name: 'pharmasan.administrativa.juridica.tutelas.ver', params: { id: base } })
      } else {
        alert('Falta el de incidencias')
      }
    }

    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })

    const onEditar = (data) => {
      router.push({ name: 'pharmasan.administrativa.juridica.sanciones.editar', params: { id: data.id } })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      paginator.value.offset = first
      paginator.value.page = page
      desacatosStore.dispatch('getPaginate', {
        paginator: paginator.value
      })
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este juzgado no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          desacatosStore.dispatch('removeDesacato', id).then(async () => {
            await desacatosStore.dispatch('getPaginate', {
              paginator: paginator.value
            })
            await Swal.fire(
              'Eliminado!',
              'El Desacato fue eliminado con exito.',
              'success'
            )
          })
        }
      })
    }

    onMounted(() => {
      desacatosStore.dispatch('getPaginate', {
        paginator: paginator.value
      })
      console.log('hoal')
    })

    return {
      paginator,
      onPage,
      remove,
      onEditar,
      verPdf,
      usuarios,
      ver,
      sanciones
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>
